<script setup lang="ts">
import type { Sticker } from '@/store/entity-system/useStickersStore'
import stickerLibrary from '@/components/Stickers/stickerLibrary/stickerLibrary'
import { computed, ref, onMounted, watch, toRaw } from 'vue'
import { useElementSize } from '@vueuse/core'
import type TextSticker from '@/components/Stickers/TextSticker.vue'
import textLibrary from '@/components/Stickers/textLibrary'
import { omit, debounce } from 'lodash-es'
import type { ClassValue } from 'clsx'
import GiphyElement from '@/components/Stickers/GiphyElement.vue'

const props = defineProps<{
  id?: string, class?: ClassValue,
  renderWidth: number, renderHeight: number,
}>()

const emit = defineEmits<{
  (event: 'updateContent', text: string): void
  (event: 'ready'): void
}>()

const sticker = defineModel<Sticker>({ required: true })

const stickerMetaData = computed(() => {
  if (sticker.value.type === 'giphy') {
    return { component: toRaw(GiphyElement) }
  } else {
    return [...textLibrary, ...stickerLibrary].find(({ key }) => key === sticker.value.key);
  }
})

const stickerBinding = computed(() => {
  return {
    ...omit(sticker.value, ['key', 'area', 'id', 'z', 'type', 'textContent', 'scale']),
    ...omit(stickerMetaData.value, ['component', 'key', 'colors', 'createdAt', 'tags', 'title']),
  }
})

const naturalElement = ref<HTMLDivElement>()
const { width: naturalWidth, height: naturalHeight } = useElementSize(naturalElement)

const absoluteScale = computed(() => {
  if (!sticker.value.scale) {
    return 1
  } else {
    return sticker.value.scale * props.renderWidth
  }
})

const componentRef = ref<InstanceType<TextSticker>>()
defineExpose({
  setEditMode() {
    componentRef.value?.setEditMode?.()
  }
})

function updateStore() {
  setTimeout(() => {
    sticker.value.area.width = naturalWidth.value * absoluteScale.value / props.renderWidth
    sticker.value.area.height = naturalHeight.value * absoluteScale.value / props.renderHeight
  }, 0)
}

const debouncedUpdateStore = debounce(updateStore, 100)
const hide = ref(true)
onMounted(() => {

  if (sticker.value.scale === null) {
    const scale = sticker.value.area.width * props.renderWidth / naturalWidth.value
    sticker.value.scale = scale / props.renderWidth
  }

  // Wait one tick to fix aspect ratio
  setTimeout(() => {
    hide.value = false
    emit('ready')
  }, 50)
})

watch(() => sticker.value.area.width * props.renderWidth, (scaledWidth) => {
  const scale = scaledWidth / naturalWidth.value
  sticker.value.scale = scale / props.renderWidth
}, { deep: true })

function contain() {

  if (!naturalElement.value) return

  const width = naturalElement.value.clientWidth
  const height = naturalElement.value.clientHeight

  const maxWidth = 1.1 - sticker.value.area.x
  const maxHeight = 1.1 - sticker.value.area.y

  const containScale = Math.min(1,
    maxWidth * props.renderWidth / width,
    maxHeight * props.renderHeight / height)

  sticker.value.area.width = width * absoluteScale.value * containScale / props.renderWidth
  sticker.value.area.height = height * absoluteScale.value * containScale / props.renderHeight
}

watch(
  () => sticker.value.scale,
  () => debouncedUpdateStore(),
  { deep: true, immediate: true })

function updateContent(text: string) {
  if ('textContent' in sticker.value) {
    sticker.value.editing = false
    sticker.value.textContent = text
    setTimeout(contain, 0)
  } else {
    debouncedUpdateStore()
    emit('updateContent', text)
  }
}

const input = ref(sticker.value.textContent)
const element = ref<HTMLDivElement>()

const padding = computed(() => sticker.value.type === 'custom' ? 0 : 30)
</script>

<template>
  <template v-if="stickerMetaData?.component">
    <div ref="element" :id="props.id"
      class="origin-top-left relative"
      :style="{
        transform: `scale(${absoluteScale})`, 
        width: naturalWidth + (2 * padding) + 'px',
        padding: padding + 'px',
        margin: `-${padding * absoluteScale}px 0 0 -${padding * absoluteScale}px`,
        display: hide ? 'none' : undefined
      }"
      :class="props.class"
    >
      <component ref="componentRef" :editable="true"
        class="whitespace-pre [&_*]:!whitespace-pre"
        @onAssetLoaded="updateContent"
        :is="stickerMetaData.component"
        :htmlContent="'textContent' in sticker ? sticker.textContent : null"
        @updateRect="(e: string) => input = e"
        @updateContent="updateContent"
        v-bind="stickerBinding"
      />
    </div>
    <div ref="naturalElement" class="fixed top-0 left-0 opacity-0 invisible pointer-events-none whitespace-pre [&_*]:!whitespace-pre">
      <component class="relative" :is="stickerMetaData.component" :htmlContent="input" v-bind="stickerBinding" :editable="false" />
    </div>
  </template>
</template>

<style scoped lang="scss">

</style>
